.product{
    background-color: rgb(240, 240, 240);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    margin: 5px;
    padding: 10px;
}

.description {
    align-items: center;
}