/* Customize the appearance of the scrollbar */
::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }
  
  /* Make the track transparent */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Round the corners of the thumb */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Color of the thumb */
    border-radius: 6px; /* Adjust the value for rounded corners */
  }
  
  /* On hover, the thumb will have a different color */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  