:root {
    --primary-color-0: #A760F3;
    --primary-color-1: #F9F3FE;
    --primary-color-2: #C89CF6;
    --primary-color-3: #8A29F3;
    --primary-color-4: #4d009f;
    --neutral-color-0: rgb(255, 255, 255);
    --neutral-color-1: rgb(244, 244, 244);
    --neutral-color-2: rgb(233, 233, 233);
    --neutral-color-3: rgb(222, 222, 222);
    --neutral-dark-color-0:rgb(155, 155, 155);
    --neutral-dark-color-1:rgb(130, 130, 130) ;
    --neutral-dark-color-2:rgb(95, 95, 95) ;
    --neutral-dark-color-3:rgb(40, 40, 40) ;
    --secondary-color: #2ecc71;
    --font-family: 'Roboto', sans-serif;
    --base-font-size: 16px; /* Change this to your base font size */
    --amplify-primary-color: var(--primary-color-3);
    --amplify-primary-tint: var(--primary-color-1);
    --amplify-primary-shade: var(--primary-color-2);
    --amplify-background-color: var(--neutral-color-0);
  }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}