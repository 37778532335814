.loading-page {
    max-width: 900px;
    width: 80%;
    background-color: var(--primary-color-1); /* Dark background color */
    color: var(--neutral-dark-color-2); /* Light text color */
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: auto;
    padding: 15px;
    text-align: center;
  }
  

  .loading-page h3 {
    font-size: 24px;
  }
  
  .loading-page p {
    font-size: 16px;
  }
  
  .loading-page-buttons-container {
    margin-top: 1rem;
  }
  
  .loading-page-button {
    padding: 8px 16px;
    margin: 0 0.5rem;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .loading-page-button.loading-page-back {
    background-color: transparent;
    color: var(--primary-color-3);
  }
  
  .loading-page-button.loading-page-back:hover {
    background-color: var(--neutral-color-2);
  }
  
  .loading-page-button.loading-page-view-results {
    background-color: var(--primary-color-2);
    color: white;
  }
  
  .loading-page-button.loading-page-view-results[disabled] {
    background-color: lightgray;
    cursor: not-allowed;
  }
  
  .loading-page-button.loading-page-view-results:not([disabled]):hover {
    background-color: var(--primary-color-3);
  }