.carousal-image{
    width: 50%;
    border-radius: 25px;
}

.carousal-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carousal-container {
    width: 60%;
}


  /* Adjust for mobile screens */
  @media (max-width: 767px) {

    .carousal-container {
        width: 100%;
    }
  }