/* Add this CSS to your existing CSS file or add a new one */

/* Style for the color boxes and rows */
.color-rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    
  }
  
  .color-box {
    width: 16%; /* Adjust the width as needed */
    position: relative;
    
  }
  
  .color-box-inner {
    width: 100%;
    height: 35px; /* Set the desired height for color boxes */
    border-radius: 5px;
    position: absolute;
    
  }  

  .fake-box-inner {
    width: 100%;
    height: 35px; /* Set the desired height for color boxes */
    border: 2px solid var(--neutral-color-2); /* Add a border or customize as needed */
    border-radius: 5px;
  }
  
  .overlay {
    z-index: 1
    
  }
  
  .picker-buttons-and-boxes {
    display: flex;
    flex-direction: column;
  }
  
  .picker-buttons {
    display: flex;
    justify-content: space-between;
    margin: 5px;

  }

  .selected-colors {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
