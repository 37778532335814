.nav-button {
    background-color: var(--primary-color-3);
    border-color: var(--primary-color-3);
    margin-right: 15px;
    margin-left: 15px;
    border-radius: 25px;
}

.nav-button:hover{
  background-color: var(--primary-color-3);
  border-color: var(--primary-color-3);
}

.sign-out-button {
  background-color: transparent;
  border-color: var(--primary-color-3);
  color: var(--primary-color-3);
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 25px;
}

.sign-out-button:hover{
  background-color: var(--primary-color-1);
  color: var(--primary-color-3);
  border-color: var(--primary-color-3);
}

.user-info-container{
  display: flex;
  justify-content: space-between;
}

/* Adjust margin for mobile screens */
@media (max-width: 991px) {
    .nav-button {
      margin-top: 15px; /* Set your desired margin for mobile screens */
    }

    .user-info-container {
      margin: 11px;
    }
  }

.collapse-button:hover{
    background-color: var(--primary-color-3);
    border-color: var(--primary-color-3);
}

.buy-button{
    justify-content: center;
    width: 110px;
    font-size: medium;
    background-color:var(--primary-color-3);
    border:none;
    color: #fff;
    border-radius: 25px;
}

.buy-button:hover{
    background-color: var(--primary-color-4);
    border-color: var(--primary-color-4);
}

.submit-button{
    padding: 10px 20px;
    background-color:var(--primary-color-3);
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 25px;
}

.submit-button:hover{
    background-color: var(--primary-color-3);
}

.picker-button {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 110px;
    font-size: small;
    padding: 5px 5px;
    margin-left: 5px;
    margin-right: 5px;
    background-color:var(--primary-color-3);
    border:none;
    color: #fff;
    border-radius: 15px;
  }

  .remove-button {
    background: transparent;
    color: var(--primary-color-3);
  }

  .remove-button:hover{
    background: var(--primary-color-1);
  }


  .download-button {
    width: 30px;
    height: 30px;
    color: var(--primary-color-3);
    transition: scale 0.3s ease, background-color 0.3s ease
  }

  .download-button-wrapper {
    width: 35px;
    height:35px;
    background-color: var(--primary-color-1);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: scale 0.3s ease, background-color 0.3s ease
  }

  .download-button-wrapper:hover{
    scale: 1.10;
    background-color: var(--primary-color-0);
  }

  .download-button:hover{
    scale: 1.05;
    color: var(--primary-color-1);
  }