.user-input-form {
    max-width: 900px;
    width: 90%;
    margin: 0 auto;
    background-color: var(--primary-color-1);
    color: var(--neutral-dark-color-3);
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-top: 20PX;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .project-description {
    width: 100%;
    min-height: 75px;
    background-color: var(--neutral-color-0); /* Darker textarea background */
    color: var(--neutral-dark-color-3); /* Light text color for textarea */
    border: 1px solid var(--neutral-color-2); /* Darker border for textarea */
    border-radius: 4px;
    resize: none; /* Disable user resizing */
    font-size: 16px;
    overflow-y: hidden;
  }
  
  /* New style for character counter */
  .character-counter {
    font-size: 12px;
    text-align: right;
    color: var(--neutral-dark-color-1);
    margin-top: 5px;
  }

  label {
    font-size: large;
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="range"] {
    width: 100%;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
  }
  
  .clear-button {
    background: none; /* Remove the background */
    border: none; /* Remove the border */
    padding: 0; /* Remove padding */
    cursor: pointer;
    font-size: large;
    color: var(--primary-color-3); /* Initial text color (blue) */
    transition: transform 0.2s; /* Add transitions for color and transform */
    margin-left: 10px;
  }
  
  .clear-button:hover {
    background: none;
    transform: scale(1.1); /* Make it slightly bigger on hover */
  }
  
  
  
  /* New section divider and header */
  .divider {
    margin: 20px 0;
    border-top: 1px solid #666;
  }
  
  .section-header {
    display: flex;
    justify-content: left;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .switch-label {
    font-size: large;
    margin: 5px;
    width: 40%;
    text-align: center;
  }

  .form-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    padding: 0px;
  }

  .switch-group {
    flex: 1;
  }
  
  .theme-group {
    display: flex;
  }



  .disabled-theme-group {
    pointer-events: none;
  }

  .color-picker-group {
    flex: 1;
  }
  
  .color-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .disabled-color-picker {
    pointer-events: none; /* Disables clicking on elements */
    filter: grayscale(100%); /* Grays out the content */
    opacity: 0.7; /* Reduces the opacity for a disabled look */
  }

  
  button[disabled] {
    background-color: #6a6a6a; /* Background color for disabled state */
    color: #666; /* Text color for disabled state */
    cursor: not-allowed; /* Change the cursor to indicate it's disabled */
    /* You can add more styles as needed */
  }
  
  .react-switch-bg div{
    display: none;
  }


  /* Container for the checkboxes */
.checkbox-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}


  /* Adjust for mobile screens */
  @media (max-width: 767px) {
    .theme-group {
      flex-direction: column; /* Set your desired margin for mobile screens */
    }

    
  }