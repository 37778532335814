
  
  .nav-tabs .nav-link.active {
    background-color: var(--primary-color-1) !important;
    color: var(--primary-color-3) !important;
    /* border-bottom: solid 4px rgb(255, 0, 0) !important; */
  }

    
  .nav-tabs .nav-link{
    color: var(--neutral-dark-color-0) !important;
    font-size: large;
  }