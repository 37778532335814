/* Styling for the CheckboxWithImage component */

.checkbox-wrapper-16 {
    box-sizing: border-box;
    width: 30%;
  }
  
  .checkbox-wrapper-16 .checkbox-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 0.5rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: 0.15s ease;
    cursor: pointer;
    position: relative;
  }
  
  .checkbox-wrapper-16 .checkbox-tile.checked {
    border-color: var(--primary-color-3);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: var(--primary-color-3);
  }
  
  .checkbox-wrapper-16 .checkbox-tile.checked:before {
    transform: scale(1);
    opacity: 1;
    background-color: var(--primary-color-3);
    border-color: var(--primary-color-3);
  }
  
  .checkbox-wrapper-16 .checkbox-tile:before {
    content: "";
    position: absolute;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
  }
  
  .checkbox-wrapper-16 .checkbox-tile:hover {
    border-color: var(--primary-color-2);
  }
  
  .checkbox-wrapper-16 .checkbox-tile:hover:before {
    transform: scale(1);
    opacity: 1;
  }
  
  .checkbox-wrapper-16 .checkbox-icon {
    transition: 0.375s ease;
    color: #494949;
  }
  
  .checkbox-wrapper-16 .checkbox-icon img {
    width: 3rem;
    height: 3rem;
  }
  
  .checkbox-wrapper-16 .checkbox-label {
    color: #707070;
    transition: 0.375s ease;
    text-align: center;
  }
      