.previous-requests-panel {
  display: flex;
  flex-direction: column;
}



.request-card-container {
    display: flex;
    flex-direction: column;
    height: 800px ;
    overflow: auto;
}

.request-card {
    background-color: var(--primary-color-1);
    color: var(--neutral-dark-color-3);
    border-radius: 25px; /* Rounded corners for the card */
    display: flex;
    align-items: center;
    text-align: center;
    transition: box-shadow 0.2s ease;
    transition: transform 0.2s ease;
    height: 100px;
    max-width: 900px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
  
  .request-card:hover {
    box-shadow: 0 0 8px var(--primary-color-0); 
    transform: scale(1.05);
  }

  .text-container {
    flex-grow: 1; /* Allow text container to take up remaining space on the left */
    text-align: left; /* Align text to the left */
    padding: 0 16px; /* Add some padding between text and image */
  }
  
  .request-card h3 {
    margin: 0;
    font-size: 1.5rem; /* Larger font size for the name */
  }
  
  .request-card h4 {
    margin: 0;
    font-size: 1rem; /* Smaller font size for the date */
    color: var(--neutral-dark-color-0); /* Lighter color for the date */
  }
  
  .logo-image {
    border-top-right-radius: 10px; /* Rounded top right corner */
    border-bottom-right-radius: 10px; /* Rounded bottom right corner */
    border-top-left-radius: 0; /* No rounding on the top left corner */
    border-bottom-left-radius: 0; /* No rounding on the bottom left corner */
    max-width: 100%;
    max-height: 100%;
  }
  

  .no-results-block{
    background-color: var(--primary-color-1);
    color: var(--neutral-dark-color-3);
    border-radius: 25px; /* Rounded corners for the card */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: box-shadow 0.2s ease;
    transition: transform 0.2s ease;
    height: 100px;
    max-width: 900px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: large;
  }