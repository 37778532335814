.amplify-button--primary {
    background-color: var(--primary-color-3); 
    border-radius: 25px;
}


.amplify-field-group__control{
    border-radius: 25px;
    border: 1px solid var(--primary-color-2);
}

.amplify-field-group__control[type="button"]:hover{
    background-color: var(--primary-color-1);
}

.amplify-field__show-password:hover {
    background-color: var(--primary-color-1);
    border: 1px solid var(--primary-color-2);
}

.amplify-field__show-password[aria-checked="true"] {
    background-color: var(--primary-color-1);
    border: 1px solid var(--primary-color-2);

}

.amplify-field__show-password[aria-checked="false"] {
    background-color: var(--neutral-color-0);

}

.amplify-field__show-password {
    border-radius: 15px;
}

.amplify-link{
    color: var(--primary-color-3);
}
.amplify-link:hover{
    color: var(--primary-color-4);
}

.amplify-tabs-item[aria-selected="true"] {
    color: var(--primary-color-3);
    border-color: var(--primary-color-2);
}

.amplify-grid {
    background-color: var(--primary-color-1);
}

.my-auth {
    border-radius: 25px;
  }

.amplify-input {
    border-radius: 15px;
}

.amplify-heading{
    color: var(--neutral-dark-color-2);
}

[data-amplify-authenticator] {
    height: 100vh;
    background-color:  var(--primary-color-1);
  }


