/* CSS - Cascading Style Sheet */
/* Palette color codes */
/* Palette URL: http://paletton.com/#uid=14x0u0kl1Wx1x+IcEXDsUWkWEVB */

/* Feel free to copy&paste color codes to your application */


/* As hex codes */


/* Generated by Paletton.com © 2002-2014 */
/* http://paletton.com */

/* Link to Google Fonts (Roboto) */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Apply Roboto font to all header elements */
body, div {
  font-family: 'Montserrat', sans-serif;
  color: var(--neutral-dark-color-3);
  /* Other styles for headers */
}